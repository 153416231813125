// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  pureSpectrum: {
    url: 'https://qa1-api.spectrumsurveys.com',
    activityUrl: 'https://activitystaging.spectrumsurveys.com',
    decipherUrl: 'https://decipherstaging.spectrumsurveys.com',
    samplifyUrl: 'https://samplifystaging.spectrumsurveys.com',
    ipsosUrl: 'https://ipsosstaging.spectrumsurveys.com',
    nightcrawlerUrl:
      'https://nightcrawlerapistaging.spectrumsurveys.com/api/v1',
    buyerExternalIntegrationUrl:
      'https://partnersapistaging.spectrumsurveys.com',
    reconcilationUrl: 'https://kz613jq876.execute-api.us-east-1.amazonaws.com',
    feasibilityUrl: 'https://feasibilitystaging.spectrumsurveys.com',
    priceRecommendUrl: 'https://black-box-staging.spectrumsurveys.com',
    rmfUrl: 'https://adjustments-staging.spectrumsurveys.com',
    // Used for decipher buyer settings iframe
    legacyPlatformUrl: 'https://platformstaging-legacy.spectrumsurveys.com',
    surveyStatiscticsServiceUrl:
      'https://statistics-staging.spectrumsurveys.com',
    reportServiceUrl: 'https://reportsservicestaging.spectrumsurveys.com',
    qaToolsUrl: 'https://qa_tools.spectrumsurveys.com',
  },
  BUCKET_URL: 'https://ps-stage-zip-reports.s3.amazonaws.com',
  churnzeroUrl: 'https://analytics.churnzero.net',
  CATPTCHA_SITE_KEY: '6LdrE8QUAAAAAFo5nDaqxOflsQueY74M4fxIcf1u',
  QUICKBOOK_VAT_REALMS: [4620816365168553000], // PureSpectrum Sandbox
  BUYER_TEST_LINK_SUPPLIER_ID: 26,
  PS_CMP_ID: 4,
  ENABLE_HOTJAR_EVENT: false, // Flag to indicate if Hotjar event caprturing is enabled or not
  FUSION_CMP_ID: 217,
  FUSION_API_URL: 'https://routerapistaging.spectrumsurveys.com',
  ZAPPI_STORE_BUYER_IDS: [3797, 3775],
  PUREPRICE_RATECARD_NOTIFICATION_SLACK_CHANNEL: 'jarvis-notification-staging',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
