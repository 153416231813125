import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalService, SelectDropdownComponent } from '@purespectrum1/ui';
import { EachCountry } from '../../shared/interfaces/common-service.interface';
import { BuyerService } from '../../shared/services/buyer/buyer.service';
import { OperatorSettingsService } from '../operator-settings.service';
import { OperatorSettingsResponse } from '../operator-settings.interface';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import {
  DataQualityEligibleCountry,
  DataQualityEligibleLocale,
  DataQualityTriggerCountry,
  DataQualityTriggerLocale,
  EachTriggerCountry,
  FileUpload,
  SupplierSelectable,
} from './data-quality-settings.interface';
import { CommonService } from '../../shared/services/common-service/common-service';
import { notifyMessage } from '../../constants/notify-message';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  forkJoin,
  of,
  zip,
  Subject,
} from 'rxjs';
import { CompanyService } from '../../shared/services/company/company.service';
import { CompaniesResponse } from '../../shared/interfaces/company.interface';
import { SupplierSettingObject } from '../../shared/interfaces/supplier-setting.interface';
import { PsidUploadConfirmationModalComponent } from './psid-upload-confirmation-modal/psid-upload-confirmation-modal.component';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';
import { QuestionsLibraryService } from '../../questions-library/questions-library-services/questions-library-services.service';
import { SingleBuyer } from '../config-settings/config-settings.component';
import { EachLanguage } from 'src/app/shared/interfaces/common-service.interface';
import { EditablePurescoreThresholdService } from '../../buyer/editable-purescore-threshold/editable-purescore-threshold.service';
import {
  PurescoreThresholdResponse,
  UpdatePurescoreThresholdRequest,
} from 'src/app/buyer/editable-purescore-threshold/editable-purescore-threshold.interface';
import { AuthService } from '../../auth/auth.service';
import { OeQuestionUploadConfirmationModalComponent } from './oe-question-upload-confirmation-modal/oe-question-upload-confirmation-modal.component';

@Component({
  selector: 'ps-data-quality-settings',
  templateUrl: './data-quality-settings.component.html',
  styleUrls: ['./data-quality-settings.component.css'],
})
export class DataQualitySettingsComponent implements OnInit, OnDestroy {
  form!: FormGroup;
  private _subscriptions: Subscription = new Subscription();
  private _originalCountries: Array<EachCountry> = [];
  countryList: Array<EachTriggerCountry> = [];
  triggerCountries: Array<EachTriggerCountry> = [];
  countrySupplierCountries: EachTriggerCountry[] = [];
  selectedCountryForSupplier: EachTriggerCountry | undefined;
  suppliers: SupplierSettingObject[] = [];
  dqEligibleLanguages: EachLanguage[] = [];
  dqEligibleCountry?: EachTriggerCountry;
  @ViewChild('dqEligibleLanguageSelect')
  dqEligibleLanguageSelect!: SelectDropdownComponent<EachLanguage>;
  @ViewChild('countryTriggerSelect')
  ctTriggerSelect!: SelectDropdownComponent<EachTriggerCountry>;
  @ViewChild('deviceIntelligenceCountriesSelect')
  deviceIntelligenceCountriesSelect!: SelectDropdownComponent<EachTriggerCountry>;
  @ViewChild('countrySupplierSelect')
  set countrySupplierSelect(value: ElementRef) {
    if (value) {
      this._cTSupplierSelect =
        value as any as SelectDropdownComponent<SupplierSelectable>;
    }
  }
  private _cTSupplierSelect!: SelectDropdownComponent<SupplierSelectable>;
  @ViewChild('desktopSupplierSelect')
  desktopSupplierSelect!: SelectDropdownComponent<SupplierSelectable>;
  @ViewChild('accountLevelTriggerSelect')
  accountLevelTriggerSelect!: SelectDropdownComponent<SingleBuyer>;
  buyers: SingleBuyer[] = [];

  @ViewChild('atQualificationTriggerSelect')
  atQualificationTriggerSelect!: SelectDropdownComponent<any>;
  atQualificationSubscription?: Subscription;
  psidUpload: FileUpload = {
    filename: null,
    file: null,
  };
  oeUpload: FileUpload = {
    filename: null,
    file: null,
  };

  qualificationList: any[] = [];
  private _qualificationFirstTime = true;
  initialQualificationItems$ = new Subject<any[]>();
  qualificationSearchInput$ = new BehaviorSubject<string>('');

  @ViewChild('_purescoreThresholdLanguageSelect')
  private _purescoreThresholdLanguageSelect!: SelectDropdownComponent<EachLanguage>;
  @ViewChild('_purescoreThresholdCountrySelect')
  private _purescoreThresholdCountrySelect!: SelectDropdownComponent<EachLanguage>;
  purescoreThresholdControl = new FormControl('5');
  purescoreThresholdCountries: EachCountry[] = [];
  purescoreThresholdCountry: EachCountry | undefined = undefined;
  purescoreThresholdLanguage: EachLanguage | undefined = undefined;
  isPurescoreThresholdEditable: boolean = false;

  constructor(
    private _fb: FormBuilder,
    private _operatorSettingsService: OperatorSettingsService,
    private _toastr: ToasterService,
    private _commonService: CommonService,
    private _companyService: CompanyService,
    private _modalService: ModalService,
    private _questionsLibraryService: QuestionsLibraryService,
    private _buyerService: BuyerService,
    private _pureThresholdService: EditablePurescoreThresholdService,
    private _auth: AuthService
  ) {}

  _getBuyers() {
    const getBuyers = this._buyerService.getBuyers().subscribe(
      (buyerResponse) => {
        this.buyers = buyerResponse.company;
        this.tryPreselectAccountLevelTrigger();
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
    this._subscriptions.add(getBuyers);
  }

  ngOnInit(): void {
    this._getCountries();
    this._generateForm();
    this._getOperatorSettings();
    this._getSuppliersForDesktopTrigger();
    this._getBuyers();
    this._initPurescoreThreshold();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  private _matchCountriesOnce() {
    if (
      this.countryList.length == 0 &&
      this.form.value.company !== null &&
      this._originalCountries &&
      this._originalCountries.length
    ) {
      this.countryList = this._originalCountries.map((ct) => {
        const dqTriggerLocale: DataQualityTriggerCountry[] = Array.from(
          this.form.value.dqTriggerLocale || []
        );
        const dqctSuppliers = dqTriggerLocale.find(
          (dqct) => dqct.short_code === ct.short_Code
        )?.suppliers;
        return { ...ct, suppliers: dqctSuppliers || [] };
      });
      this._generateTriggerCountries(
        this.form.value.dqEligibleLocale,
        this.countryList
      );
      this._generateCountrySupplierCountries(
        this.form.value.dqTriggerLocale,
        this.countryList
      );
      this.tryPreselectDeviceIntelligenceCountries();
      this.tryPreselectTriggerCountries();
    }
  }
  _generateTriggerCountries(
    eligibleLocaleForm: DataQualityEligibleLocale[],
    countryList: EachTriggerCountry[]
  ) {
    eligibleLocaleForm = eligibleLocaleForm || [];
    const eligibleCountryCodes = eligibleLocaleForm.map(
      (locale) => locale.short_code
    );
    this.triggerCountries = countryList.filter((country) =>
      eligibleCountryCodes.includes(country.short_Code)
    );
  }

  _generateCountrySupplierCountries(
    triggerLocaleForm: DataQualityTriggerLocale[],
    countryList: EachTriggerCountry[]
  ) {
    triggerLocaleForm = triggerLocaleForm || [];
    const triggeredCountryCodes = triggerLocaleForm.map(
      (locale) => locale.short_code
    );
    this.countrySupplierCountries = countryList.filter((country) =>
      triggeredCountryCodes.includes(country.short_Code)
    );
  }

  private _getOperatorSettings() {
    const getGlobalSetting = this._operatorSettingsService
      .getOperatorSettings()
      .subscribe(
        (resp: OperatorSettingsResponse) => {
          const operator = resp.operator[0];
          this.form.patchValue(operator);
          this._matchCountriesOnce();
          this._getQualificationsList();
          this.tryPreselectAccountLevelTrigger();
          this.tryPreselectTriggerCountries();
          this.tryPreselectDesktopSuppliers();
          this.tryPreselectQualificationTrigger();
        },
        (error) => {
          this._toastr.error(error.error.msg);
        }
      );
    this._subscriptions.add(getGlobalSetting);
  }

  _generateForm() {
    this.form = this._fb.group({
      company: [null, [Validators.required]],
      enableDataQuality: [false, [Validators.required]],
      enableHoneyPot: [false],
      enableDQBlackListTerm: [false, [Validators.required]],
      maxDataQualityQuestionCount: [0, [Validators.required]],
      maxDQFailCount: [0],
      dqEligibleLocale: [{}],
      dqTriggerLocale: [{}],
      enableTranslationServiceDetection: [false, [Validators.required]],
      enableClipboardDetection: [false, [Validators.required]],
      enablePSDeviceFingerprinting: [false, [Validators.required]],
      enableRespondentIdentificationService: [false, Validators.required],
      atQualificationTrigger: [null, [Validators.required]],
      desktopSupplierTrigger: [null, [Validators.required]],
      accountLevelTrigger: [null, [Validators.required]],
      enableDragNDrop: [false],
      enablePSCaptcha: [false, [Validators.required]],
      diEnabledCountries: [{}],
      enableVerisoul: [false, [Validators.required]],
      enableImpossibleTravelTermination: [false, [Validators.required]],
      enableMultiSessionDetection: [false, [Validators.required]],
      enableInconsistentNetworkTermination: [false, [Validators.required]],
      enableIPMismatchTermination: [false, [Validators.required]],
      enablePureTextDailySweep: [false, [Validators.required]],
    });
  }

  private _initialQualificationItems(): Observable<any[]> {
    const selectedQualificationIds =
      this.form.controls.atQualificationTrigger?.value || [];
    if (selectedQualificationIds) {
      const requests = selectedQualificationIds.map(
        (selectedQualificationId: number) => {
          return this._questionsLibraryService
            .getQuestionById(selectedQualificationId)
            .pipe(
              map((ques) => {
                return { id: ques.qualification_code, name: ques.desc };
              })
            );
        }
      );

      return forkJoin(requests);
    }

    return of([]);
  }

  private _getQualificationsList() {
    this.initialQualificationItems$ = new Subject<any[]>();
    // InitialValues + Search
    const zippedQualification = zip(
      this.initialQualificationItems$,
      this.qualificationSearchInput$.pipe(
        distinctUntilChanged(),
        debounceTime(1000),
        switchMap((term: string) => {
          return this._questionsLibraryService.getQuestions(1, term).pipe(
            catchError(() => of([])), // Empty list on error
            map((res: any) => {
              return res.questions.map((question: any) => ({
                id: question.qualification_id[0],
                name: question.question_description,
              }));
            })
          );
        })
      )
    ).pipe(
      map((x) =>
        x[0]
          .concat(x[1])
          .filter(
            (value, index, array) =>
              array.findIndex((inner) => inner.id == value.id) === index
          )
      )
    );
    this._initialQualificationItems().subscribe((res) => {
      this.initialQualificationItems$.next(res);
    });

    zippedQualification.subscribe(this._getZippedQualification.bind(this));
    // Direct DOM listening for keyup
    this.atQualificationTriggerSelect?.searchInput?.nativeElement?.addEventListener(
      'keyup',
      this._atQualificationKeyup.bind(this)
    );
    this.initialQualificationItems$.next([]);
  }

  private _atQualificationKeyup() {
    this.atQualificationSubscription?.unsubscribe();
    this.atQualificationSubscription =
      this._initialQualificationItems().subscribe((res) => {
        this.initialQualificationItems$.next(res);
      });
    this.qualificationSearchInput$.next(
      this.atQualificationTriggerSelect?.searchTextControl.value
    );
  }

  private _getZippedQualification(response: any[]) {
    this.qualificationList = response;
    this.tryPreselectQualificationTrigger();
  }

  private _getAllSuppliers(): Observable<SupplierSettingObject[]> {
    return this._companyService.getCompanies().pipe(
      map((response: CompaniesResponse) => {
        return response.companies
          .filter(
            (company) =>
              company.isASupplier || company.isSpplr || company.isFusion
          )
          .map((supplier) => {
            return {
              name: supplier.name,
              id: supplier.id,
              supplierType: supplier.supplier_type === 'public' ? false : true,
              dynamicProfiler: supplier.dynamicProfiler,
              isPrivacyPolicyActive: supplier.isPrivacyPolicyActive,
              isDataQualityQuestionsActive:
                !!supplier.isDataQualityQuestionsActive,
              isAsrRedirectionEnable: supplier.isAsrRedirectionEnable,
              skipPreScreener: supplier.skipPreScreener,
            };
          });
      })
    );
  }

  private _subSupplierOnce(callback: Function): boolean {
    if (this.suppliers.length != 0) {
      return false;
    }
    const suppliers$ = this._getAllSuppliers().subscribe(
      (suppliers) => {
        this.suppliers = suppliers;
        callback();
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
    this._subscriptions.add(suppliers$);
    return true;
  }

  selectCountry(event: EachTriggerCountry) {
    this.dqEligibleCountry = event;
    this.tryPreselectEligibleLanguages();
    this._generateTriggerCountries(
      this.form.value.dqEligibleLocale,
      this.countryList
    );
  }

  tryPreselectEligibleLanguages() {
    const validation = [
      this.dqEligibleCountry !== undefined,
      this.form.value.dqEligibleLocale,
      this.form.value.dqEligibleLocale.length > 0,
    ];
    if (!validation.every((v) => v)) {
      return;
    }
    const selectedDQCountry: EachTriggerCountry = this
      .dqEligibleCountry as EachTriggerCountry;
    const dqEligibleLocale: DataQualityEligibleLocale[] =
      this.form.value.dqEligibleLocale;
    const dqEligibleCountry = dqEligibleLocale.find(
      (locale) => locale.short_code == selectedDQCountry.short_Code
    );
    const localeValidation = [
      dqEligibleCountry !== undefined,
      dqEligibleCountry?.lang,
      (dqEligibleCountry?.lang?.length || 0) > 0,
    ];
    if (!localeValidation.every((v) => v)) {
      this.dqEligibleLanguageSelect.selectNone();
      return;
    }

    const dqEligibleLanguage = dqEligibleCountry!.lang;
    const langArray: EachLanguage[] = selectedDQCountry.lang.filter((lang) =>
      dqEligibleLanguage.includes(lang.transalte_code)
    );
    this.dqEligibleLanguageSelect.selected = langArray;
  }
  selectDQEligibleLanguages(event: EachLanguage[]) {
    if (this.dqEligibleCountry === undefined) {
      console.warn('attempting to select language without country');
      return;
    }
    let eligibleLocaleForm: DataQualityEligibleLocale[] =
      this.form.value.dqEligibleLocale;
    const selectedCountry = this.dqEligibleCountry!;
    const foundCountry =
      eligibleLocaleForm.find(
        (locale) => locale.short_code == selectedCountry.short_Code
      ) !== undefined;
    const deleteSelectedCountry = event.length === 0;

    if (!deleteSelectedCountry && !foundCountry) {
      eligibleLocaleForm.push({
        short_code: selectedCountry.short_Code,
        lang: [],
      });
    }

    if (deleteSelectedCountry) {
      eligibleLocaleForm = eligibleLocaleForm.filter(
        (locale) => locale.short_code !== selectedCountry.short_Code
      );
    } else {
      const formIndex = eligibleLocaleForm.findIndex(
        (locale) => locale.short_code == selectedCountry.short_Code
      );
      eligibleLocaleForm[formIndex].lang = event.map(
        (lang) => lang.transalte_code
      );
    }
    this.form.controls.dqEligibleLocale.patchValue(eligibleLocaleForm);
    this._generateTriggerCountries(eligibleLocaleForm, this.countryList);
    this.tryPreselectTriggerCountries();
    if (this.ctTriggerSelect.selected?.length) {
      let filterTriggerCountry = this.ctTriggerSelect.selected.filter((trig) =>
        event.find((elig) => trig.id === elig.id)
      );
      this.selectTriggerCountries(filterTriggerCountry);
      this.ctTriggerSelect.selected = filterTriggerCountry;
    }
  }
  tryPreselectQualificationTrigger() {
    const isPreselectable = [
      this.qualificationList,
      this.qualificationList?.length > 0,
      this.form.value?.atQualificationTrigger,
      this.form.value?.atQualificationTrigger?.length > 0,
      this._qualificationFirstTime,
    ];
    if (isPreselectable.every(Boolean)) {
      const selectList = this.qualificationList.filter(
        (ql) =>
          this.form.value.atQualificationTrigger.findIndex(
            (obj: any) => obj == ql.id
          ) >= 0
      );
      this.atQualificationTriggerSelect.selected = selectList;
      this._qualificationFirstTime = false;
    }
  }

  selectQualificationTrigger(select: any) {
    const selectedId = select.map((x: any) => x.id);
    this.form.controls.atQualificationTrigger.patchValue(selectedId);
  }

  selectCountryForSupplier(selCountry: EachTriggerCountry) {
    this.selectedCountryForSupplier = selCountry;
    const alreadySubscribed = !this._subSupplierOnce(
      this.tryPreselectSupplierForCountry.bind(this)
    );
    if (alreadySubscribed) {
      this.tryPreselectSupplierForCountry();
    }
  }

  selectSuppliersForCountry(selSupplier: Array<SupplierSelectable>) {
    if (this.selectedCountryForSupplier === undefined) {
      return;
    }
    const suppIds = selSupplier.map((x) => x.id);
    const selectedCountryForSupplier: EachTriggerCountry =
      this.selectedCountryForSupplier;
    const countryCode = selectedCountryForSupplier.short_Code;
    const dqTriggerLocale = this.ctTriggerSelect.selected;
    const idx = dqTriggerLocale.findIndex(
      (x: EachTriggerCountry) => x.short_Code === countryCode
    );
    if (idx >= 0) {
      dqTriggerLocale[idx].suppliers = suppIds;
      this.selectTriggerCountries(dqTriggerLocale);
      return;
    }
    console.warn('uneditable index');
  }

  tryPreselectSupplierForCountry() {
    if (
      this.selectedCountryForSupplier === undefined ||
      this._cTSupplierSelect === undefined
    ) {
      return;
    }
    const selectedCountryForSupplier: EachTriggerCountry =
      this.selectedCountryForSupplier;
    const countryCode = selectedCountryForSupplier.short_Code;
    const dqTriggerLocale = this.form.value.dqTriggerLocale;
    const preselectCountry = dqTriggerLocale.find(
      (x: DataQualityEligibleCountry) => x.short_code == countryCode
    );
    const preselectSuppliers = preselectCountry?.suppliers || [];
    const selectedSuppliers =
      this.suppliers.filter((sup) => preselectSuppliers.includes(sup.id)) || [];
    this._cTSupplierSelect.selected = selectedSuppliers;
  }

  selectTriggerCountries(event: Array<EachTriggerCountry>) {
    const dqTriggerCountrySelected = event.map((ct) => ({
      short_code: ct.short_Code,
      lang: ct.lang.map((lg) => lg.transalte_code),
      suppliers: ct.suppliers,
    }));
    this.form.controls.dqTriggerLocale.patchValue(dqTriggerCountrySelected);
    this._generateCountrySupplierCountries(
      this.form.value.dqTriggerLocale,
      this.countryList
    );
  }

  tryPreselectTriggerCountries() {
    const isPreselectable =
      this.triggerCountries &&
      this.triggerCountries.length > 0 &&
      this.form.value.dqTriggerLocale &&
      this.form.value.dqTriggerLocale.length > 0;
    if (isPreselectable) {
      const selectList = this.countryList.filter(
        (ct) =>
          this.form.value.dqTriggerLocale.findIndex(
            (obj: DataQualityTriggerCountry) => obj.short_code === ct.short_Code
          ) >= 0
      );
      this.ctTriggerSelect.selected = selectList;
      this.selectTriggerCountries(selectList);
    }
  }

  tryPreselectDeviceIntelligenceCountries() {
    const isPreselectable = [
      this.form?.value?.diEnabledCountries,
      (this.form?.value?.diEnabledCountries?.length ?? 0) > 0,
      (this.countryList?.length ?? 0) > 0,
    ].every(Boolean);
    if (!isPreselectable) {
      return;
    }
    const selectList = this.countryList.filter(
      (ct) =>
        this.form.value.diEnabledCountries.findIndex(
          (obj: string) => obj.toUpperCase() == ct.short_Code?.toUpperCase()
        ) >= 0
    );
    this.deviceIntelligenceCountriesSelect.selected = selectList;
  }

  selecteDeviceIntelligenceCountries(selected: EachTriggerCountry[]) {
    const countryCodes = selected.map((cd) => cd.short_Code.toUpperCase());
    this.form.controls.diEnabledCountries.patchValue(countryCodes);
  }

  private _getCountries() {
    const sub = this._commonService.getCountries().subscribe((response) => {
      this._originalCountries = response;
      this.purescoreThresholdCountries = [...response];
      this._purescoreThresholdCountrySelect?.selectNone();
      this._matchCountriesOnce();
    });
    this._subscriptions.add(sub);
  }

  updateDQSettings() {
    const updateObj = this.form.value;
    updateObj.maxDQFailCount = updateObj.maxDQFailCount || 0;
    updateObj.maxDataQualityQuestionCount =
      updateObj.maxDataQualityQuestionCount || 0;
    const isValid = this._validateDQSettings(updateObj);
    if (!isValid) {
      return;
    }
    this._operatorSettingsService
      .updateOperatorSettings(updateObj.company, updateObj)
      .subscribe(
        (res) => {
          this._toastr.success(
            notifyMessage.successMessage.OPERATOR_SETTINGS.GLOBAL_SETTINGS
              .SETTINGS_SAVED
          );
        },
        (error) => {
          this._toastr.error(
            error.error.msg || notifyMessage.errorMessage.SERVER_ERROR
          );
        }
      );
  }

  private _validateDQSettings(updateObj: { [key: string]: unknown }) {
    if (updateObj.enabledataquality && !updateObj.maxDataQualityQuestionCount) {
      this._toastr.warning(
        notifyMessage.warningMessage.OPERATOR_SETTINGS.GLOBAL_SETTINGS
          .DATA_QUALITY_QUESTION_INVALID_VALUE
      );
      return false;
    }
    if (updateObj.enableDQBlackListTerm && !updateObj.maxDQFailCount) {
      this._toastr.warning(
        notifyMessage.warningMessage.OPERATOR_SETTINGS.GLOBAL_SETTINGS
          .TLQ_QUESTION_INVALID_VALUE
      );
      return false;
    }
    return true;
  }

  uploadPSIDs() {
    if (this.psidUpload.file) {
      const formdata = new FormData();
      formdata.append('file', this.psidUpload.file);

      this._operatorSettingsService.uploadPSIDs(formdata).subscribe({
        next: (data) => {
          this.psidUpload.file = null;
          this.psidUpload.filename = null;
          this._modalService.open(PsidUploadConfirmationModalComponent, {
            data,
            width: '600px',
          });
        },
        error: (err) => {
          this._toastr.error(err.error.msg);
        },
      });
    }
  }

  uploadOEQuestions() {
    if (this.oeUpload.file) {
      const formdata = new FormData();
      formdata.append('file', this.oeUpload.file);

      this._questionsLibraryService.uploadOEQuestions(formdata).subscribe({
        next: (data) => {
          this.oeUpload.file = null;
          this.oeUpload.filename = null;

          this._modalService.open(OeQuestionUploadConfirmationModalComponent, {
            data,
            width: '600px',
          });
        },
        error: (err) => {
          this._toastr.error(err.error.ps_api_response_message);
        },
      });
    }
  }

  onFileChange(
    event: Event,
    data: FileUpload,
    validExt: string[] | null = null
  ) {
    const element = <HTMLInputElement>event.currentTarget;
    data.file = (element.files as FileList)[0];
    if (element.files !== null) {
      data.filename = data.file?.name;
      if (element.files.length == 0) {
        this._toastr.warning(notifyMessage.warningMessage.NO_FILE);
        return;
      }
      if (!this._validateFileExtention(data.file, validExt)) {
        data.filename = '';
      }
    }
  }

  private _validateFileExtention(file: File, ext: string[] | null) {
    const validExt = ext || ['.xls', '.xlsx', '.csv'];
    if (!file) {
      this._toastr.error(notifyMessage.errorMessage.FILE_UPLOAD_MISSING);
      return false;
    }
    const fileExt = file.name.substring(file.name.lastIndexOf('.'));
    if (validExt.indexOf(fileExt) < 0) {
      this._toastr.error(
        notifyMessage.errorMessage.FILE_UPLOAD_ASK_EXTERNAL_BUYER
      );
      return false;
    }
    return true;
  }

  private _getSuppliersForDesktopTrigger() {
    const suppliersForDesktopTrigger$ = this._getAllSuppliers().subscribe(
      (suppliers) => {
        this.suppliers = suppliers;
        this.tryPreselectDesktopSuppliers();
      },
      (error) => {
        this._toastr.error(error.error.msg);
      }
    );
    this._subscriptions.add(suppliersForDesktopTrigger$);
  }

  selectSupplierForDesktopTrigger(selectedSuppliers: SupplierSettingObject[]) {
    const ids = selectedSuppliers.map((item: any) => item.id);
    this.form.controls.desktopSupplierTrigger.patchValue(ids);
  }

  tryPreselectDesktopSuppliers() {
    const isSelectable =
      this.suppliers &&
      this.suppliers.length > 0 &&
      this.form.value.desktopSupplierTrigger &&
      this.form.value.desktopSupplierTrigger.length > 0;
    if (isSelectable) {
      const selectList = this.suppliers.filter((supp) =>
        this.form.value.desktopSupplierTrigger.includes(supp.id)
      );
      this.desktopSupplierSelect.selected = selectList;
    }
  }

  selectAccountLevelTrigger(selectedBuyers: SingleBuyer[]) {
    const ids = selectedBuyers.map((item: any) => item.id);
    this.form.controls.accountLevelTrigger.patchValue(ids);
  }

  tryPreselectAccountLevelTrigger() {
    const conditions = [
      this?.buyers,
      (this?.buyers?.length || 0) > 0,
      this?.form?.value?.accountLevelTrigger,
      this?.form?.value?.accountLevelTrigger?.length > 0,
    ];
    const isSelectable = conditions.every(Boolean);
    if (isSelectable) {
      const selectList = this.buyers.filter((buyer) =>
        this.form.value.accountLevelTrigger.includes(buyer.id)
      );
      this.accountLevelTriggerSelect.selected = selectList;
    }
  }

  get isPurescoreThresholdDisabled() {
    return (
      this.purescoreThresholdCountry === undefined ||
      this.purescoreThresholdCountries.length === 0
    );
  }

  selectThresholdCountry(selectedCountry: EachCountry | undefined) {
    this.purescoreThresholdCountry = selectedCountry;
    this._purescoreThresholdLanguageSelect?.selectNone();
  }

  selectThresholdLanguage(selectedLanguage: EachLanguage) {
    this.purescoreThresholdLanguage = selectedLanguage;
    const locale = this.getPurescoreThresholdLocale();
    if (!locale) {
      return;
    }
    this._pureThresholdService
      .getEditablePurescoreThreshold(locale, this._auth.token!)
      .subscribe(
        (response) => {
          const res = response as PurescoreThresholdResponse;
          this.purescoreThresholdControl.setValue(res.value);
        },
        (error: any) => {
          this._toastr.error(error.error.msg);
        }
      );
  }

  getPurescoreThresholdLocale() {
    if (!this.isPurescoreThresholdDisabled && this.purescoreThresholdLanguage) {
      return `${this.purescoreThresholdLanguage.transalte_code}_${this.purescoreThresholdCountry?.short_Code}`;
    }
    return undefined;
  }

  purescoreThresholdToggle() {
    if (this.isPurescoreThresholdDisabled) {
      this.purescoreThresholdControl.disable();
      return;
    }
    if (this.purescoreThresholdControl.disabled) {
      this.purescoreThresholdControl.enable();
    } else {
      this.purescoreThresholdControl.disable();
    }
  }

  confirmPurescoreThreshold() {
    const thresholdValue = parseInt(this.purescoreThresholdControl.value) || 5;
    const conditions = [
      !this.isPurescoreThresholdDisabled,
      this.purescoreThresholdLanguage !== undefined,
      thresholdValue >= 0,
      thresholdValue <= 10,
    ];
    if (!conditions.every(Boolean)) {
      this._toastr.error(
        notifyMessage.errorMessage.OPERATOR_SETTINGS.DATA_QUALITY
          .INVALID_PURESCORE_THRESHOLD
      );
      return;
    }
    const locale = this.getPurescoreThresholdLocale();
    const newObject: PurescoreThresholdResponse = {
      locale: locale!,
      countryName: this.purescoreThresholdCountry!.name,
      value: thresholdValue,
    };
    this._pureThresholdService
      .createEditablePurescoreThreshold(newObject, this._auth.token!)
      .subscribe(
        () => {
          this._purescoreThresholdCleanup();
          this._toastr.success(
            notifyMessage.successMessage.OPERATOR_SETTINGS.DATA_QUALITY
              .PURESCORE_THRESHOLD_CREATED
          );
        },
        (_error) => {
          this._updatePurescoreThreshold(locale!, thresholdValue);
        }
      );
  }

  private _purescoreThresholdCleanup() {
    this.isPurescoreThresholdEditable = false;
    this._purescoreThresholdLanguageSelect.selectNone();
    this._purescoreThresholdCountrySelect.selectNone();
    this.purescoreThresholdControl.setValue('5');
    this.purescoreThresholdControl.disable();
  }

  private _updatePurescoreThreshold(locale: string, thresholdValue: number) {
    const updateObject: UpdatePurescoreThresholdRequest = {
      locale: locale,
      value: thresholdValue,
    };
    this._pureThresholdService
      .updateEditablePurescoreThreshold(updateObject, this._auth.token!)
      .subscribe(
        () => {
          this._purescoreThresholdCleanup();
          this._toastr.success(
            notifyMessage.successMessage.OPERATOR_SETTINGS.DATA_QUALITY
              .PURESCORE_THRESHOLD_UPDATED
          );
        },
        (updateError: any) => {
          this._toastr.error(updateError.error.msg);
        }
      );
  }

  private _initPurescoreThreshold() {
    this.purescoreThresholdControl.disable();
    this.purescoreThresholdControl.valueChanges.subscribe((value) => {
      const pattern = /^[0-9]0?$/;
      const altPattern = /[0-9]0?$/;
      if (pattern.test(value)) {
        this.purescoreThresholdControl.setValue(value, { emitEvent: false });
      } else {
        const res = altPattern.exec(value) || ['5'];
        this.purescoreThresholdControl.setValue(res[0], { emitEvent: false });
      }
    });
  }
}
